import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../button/button.jsx';
import styles from './login.css';

const SubmitLoginButton = ({
    className,
    onClick,
    disabled,
    ...props
}) => (
    <div className={className}>
        <Button
            className={classNames(
                styles.btnSubmit,
                disabled ? styles.submitLoginButtonDisabled : styles.submitLoginButton
            )}
            onClick={disabled ? null : onClick}
        >
            <React.Fragment>
                {props.isRegister ? (
                    <FormattedMessage
                        defaultMessage="注册"
                        description="Label for sign up"
                        id="gui.loginModal.register"
                    />
                ) : (
                    <FormattedMessage
                        defaultMessage="登录"
                        description="Label for submit login"
                        id="gui.loginModal.submitLogin"
                    />
                )}
            </React.Fragment>
        </Button>
    </div>
);
SubmitLoginButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    isRegister: PropTypes.bool,
    disabled: PropTypes.bool
};
SubmitLoginButton.defaultProps = {
    onClick: () => {
    }
};
export default SubmitLoginButton;
