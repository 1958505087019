import {miniFetch} from './request.js';

// 上传作品
const uploadWork = function (resolve, reject, data) {
    return miniFetch(resolve, reject, {
        url: '/v1/worksLib',
        method: 'post',
        mode: 'cors',
        data: data
    });
};

// 修改作品
const putWork = function (resolve, reject, data) {
    return miniFetch(resolve, reject, {
        url: '/v1/worksLib',
        method: 'put',
        mode: 'cors',
        data: data
    });
};

export {
    uploadWork,
    putWork
};
