import CustomModal from '../../containers/custom-modal.jsx';
import styles from './login.css';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../box/box.jsx';
import SubmitLoginButton from './submit-login-button.jsx';
import {FormattedMessage, injectIntl} from 'react-intl';
import Button from '../button/button.jsx';
import VerifyCode from './verify-code.jsx';
import {encryptedData} from '../../lib/api/rsa.js';
import closeLoginModal from '../../reducers/modals.js';
import {requestEmailCode, requestEmailRegister, requestLogin} from '../../lib/api/login.js';

class LoginModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            verifyCode: '',
            error: false,
            errorMessage: '',
            isRegister: false,
            isAgree: false,
            isClosed: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCode = this.handleCode.bind(this);

        this.handleRegister = () => {
            this.setState({
                isRegister: !this.state.isRegister
            });
        };
    }

    handleChange (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        // not null
        if (name === 'username' && value.length > 0) {
            this.setState({
                error: false
            });
        }
        if (name === 'password' && value.length > 0) {
            this.setState({
                error: false
            });
        }
        if (name === 'verifyCode' && value.length > 0) {
            this.setState({
                error: false
            });
        }
        // checkbox
        if (name === 'isAgree' && value) {
            this.setState({
                error: false
            });
        }
        console.log(this.state);
    }

    register (username, password, verifyCode) {
        new Promise((resolve, reject) => {
            requestEmailRegister(resolve, reject, {
                email: username,
                password: encryptedData(password),
                code: verifyCode,
                type: 0
            });
        })
            .then(response => {
                // console.log('parsed json', response);
                localStorage.setItem('token', response.data.accessToken);
                // if (response.code === 0) {
                //     this.props.dispatch(closeLoginModal());
                // }
                this.setState({
                    isClosed: true
                });
                this.props.onSubmit();
                setTimeout(() => {
                    this.props.onCancel();
                }, 1000);
            })
            .catch(ex => {
                ex.then(response => {
                    console.log('parsing catch', response);
                    this.setState({
                        error: true,
                        errorMessage: response.msg
                    });
                });
            });
    }

    handleSubmit (event) {
        event.preventDefault();
        const {username, password, verifyCode, isAgree, isRegister} = this.state;
        // not null
        if (!username || !password) {
            this.setState({
                error: true,
                errorMessage: '用户名或密码不能为空'
            });
            return;
        }
        // checkbox
        if (!isAgree) {
            this.setState({
                error: true,
                errorMessage: '请阅读并同意用户协议'
            });
            return;
        }
        // verifyCode
        if (isRegister && !verifyCode) {
            this.setState({
                error: true,
                errorMessage: '验证码不能为空'
            });
            return;
        }

        // login or register
        if (isRegister) {
            this.register(username, password, verifyCode);
        } else {
            this.login(username, password);
        }
    }

    login (username, password) {
        new Promise((resolve, reject) => {
            requestLogin(resolve, reject, {
                email: username,
                password: encryptedData(password)
            });
        })
            .then(response => {
                // console.log('parsed json', response);
                localStorage.setItem('token', response.data.accessToken);
                // if (response.code === 0) {
                //     this.props.dispatch(closeLoginModal());
                // }
                this.setState({
                    isClosed: true
                });
                this.props.onSubmit();
                setTimeout(() => {
                    this.props.onCancel();
                }, 1000);
            })
            .catch(ex => {
                console.log('parsing catch', ex);
                ex.then(response => {
                    console.log('parsing catch', response);
                    this.setState({
                        error: true,
                        errorMessage: response.msg
                    });
                });
            });
    }

    handleCode () {
        // event.preventDefault();
        const {username, isAgree} = this.state;
        if (!username || !isAgree) {
            this.setState({
                error: true,
                errorMessage: '请输入邮箱地址并同意用户协议'
            });
            return;
        }
        new Promise((resolve, reject) => {
            requestEmailCode(resolve, reject, {
                email: username,
                type: 1
            });
        })
            .then(response => {
                console.log('parsed json', response);
            })
            .catch(ex => {
                ex.then(response => {
                    console.log('parsing catch', response);
                    this.setState({
                        error: true,
                        errorMessage: response.msg
                    });
                });
            });
    }

    loginAction = function (username, password) {
        // request({
        //     url: '/v1/user/loginByEmail',
        //     method: 'post',
        //     mode: 'cors',
        //     data: {
        //         email: username,
        //         password: encryptedData(password)
        //     }
        // })
        //     .then(response => response.json())
        //     .then(json => {
        //         console.log('parsed json', json);
        //     })
        //     .catch(ex => {
        //         console.log('parsing failed', ex);
        //     });

    };

    getBox (username, password, verifyCode, isAgree, error, errorMessage) {
        return (<Box className={styles.accountBox}>
            <form onSubmit={this.handleSubmit}>
                <input
                    required
                    className={styles.emailInput}
                    name="username"
                    placeholder="请输入邮箱地址"
                    type="email"
                    value={username}
                    onChange={this.handleChange}
                /><br />
                <input
                    required
                    className={styles.pwdInput}
                    name="password"
                    placeholder="请输入密码"
                    type="password"
                    value={password}
                    onChange={this.handleChange}
                /><br />
                {this.state.isRegister ? <React.Fragment>
                    {/* 获取验证码 */}
                    <Box className={styles.codeBox}>
                        <input
                            className={styles.pwdInput}
                            name="verifyCode"
                            placeholder="请输入验证码"
                            type="number"
                            value={verifyCode}
                            onChange={this.handleChange}
                        />
                        <VerifyCode
                            disabled={!username || !isAgree}
                            onClick={this.handleCode}
                        />
                        {/* <Button */}
                        {/*     className={styles.codeButton} */}
                        {/*     onClick={this.handleSubmit} */}
                        {/*     type="button" */}
                        {/* > */}
                        {/*     <FormattedMessage */}
                        {/*         defaultMessage="获取验证码" */}
                        {/*         description="Label for sign up" */}
                        {/*         id="gui.account.getCode" */}
                        {/*     /> */}
                        {/* </Button> */}
                    </Box><br /> </React.Fragment> : []}
                <SubmitLoginButton
                    className={styles.btnContainer}
                    isRegister={this.state.isRegister}
                    onClick={this.handleSubmit}
                    disabled={!username || !password}
                />
            </form>
            {/* 还没有账号，快来加入我们吧 */}
            <div className={styles.signUp}>
                <React.Fragment>
                    {this.state.isRegister ?
                        <FormattedMessage
                            defaultMessage="已有账号？"
                            description="Label for sign up"
                            id="gui.account.signIn"
                        /> : <FormattedMessage
                            defaultMessage="还没有账号？"
                            description="Label for sign up"
                            id="gui.account.signUp"
                        />}
                    <Button
                        className={styles.btnRegister}
                        onClick={this.handleRegister}
                    >
                        {this.state.isRegister ? <FormattedMessage
                            defaultMessage="马上登录"
                            id="gui.account.signInLong"
                        /> : <FormattedMessage
                            defaultMessage="立即注册"
                            id="gui.account.signUpLong"
                        />}
                    </Button>
                </React.Fragment>
            </div>
            {/* 隐私协议和用户协议 */}
            <div className={styles.privacy}>
                <input
                    className={styles.checkboxRound}
                    name="isAgree"
                    type="checkbox"
                    checked={isAgree}
                    onChange={this.handleChange}
                />
                {/* <div className={styles.round}> */}
                {/*     <input */}
                {/*         type="checkbox" */}
                {/*         id="checkbox" */}
                {/*         name="isAgree" */}
                {/*         checked={isAgree} */}
                {/*         onChange={this.handleChange} */}
                {/*     /> */}
                {/*     <label htmlFor="checkbox" /> */}
                {/* </div> */}
                <FormattedMessage
                    defaultMessage="我已阅读并同意"
                    description="Label for submit login"
                    id="gui.account.privacy"
                />
                {/* eslint-disable-next-line no-script-url */}
                <a href="javascript:void(0);">
                    <FormattedMessage
                        defaultMessage="隐私政策"
                        id="gui.telemetryOptIn.privacyPolicyLink"
                    />
                </a>
                <FormattedMessage
                    defaultMessage="和"
                    id="gui.account.and"
                />
                {/* eslint-disable-next-line no-script-url */}
                <a href="javascript:void(0);">
                    <FormattedMessage
                        defaultMessage="用户协议"
                        id="gui.account.userAgreement"
                    />
                </a>
            </div>
            {error && <div className={styles.error}>{errorMessage}</div>}
        </Box>);
    }

    getMessage () {
        // login success
        return (
            <div className={styles.errorMessage}>
                <FormattedMessage
                    defaultMessage="登录成功"
                    description="Label for login success"
                    id="gui.account.loginSuccess"
                />
            </div>
        );
    }

    render () {
        // const {intl, onClose, isOpen} = this.props;
        const {username, password, verifyCode, error, errorMessage, isAgree, isClosed} = this.state;
        return (<CustomModal
            className={styles.modalContent}
            contentLabel={this.props.title}
            id="loginModal"
            onRequestClose={this.props.onCancel}
            isDividerShown
        >
            {isClosed ? this.getMessage() : this.getBox(username, password, verifyCode, isAgree, error, errorMessage)}
        </CustomModal>);
    }
}

LoginModal.propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    loginError: PropTypes.string
};

LoginModal.defaultProps = {
    title: '欢迎来到Matatalab',
    onCancel: () => {
        closeLoginModal();
    },
    loginError: ''
};

// const mapStateToProps = (state, ownProps) => ({
//     loginError: state.scratchGui.userState.loginError
// });
//
// const mapDispatchToProps = dispatch => ({
//     onCancel: () => dispatch(closeLoginModal())
// });
//
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(LoginModal);
export default injectIntl(LoginModal);
// export default LoginModal;
