import keyMirror from 'keymirror';

const USER_LOGGED = 'scratch-gui/user-state/USER_LOGGED';
const USER_LOGGED_OUT = 'scratch-gui/user-state/USER_LOGGED_OUT';

const UserState = keyMirror({
    NOT_LOGGED: null,
    LOGGED: null
});

const UserStates = Object.keys(UserState);

const initialState = {
    error: null,
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    loginState: localStorage.getItem('token') ? UserState.LOGGED : UserState.NOT_LOGGED
};

const getIsLogged = loginState => (
    loginState === UserState.LOGGED
);

const setUserLogged = userData => ({
    type: USER_LOGGED,
    userData: userData
});

const setUserLoggedOut = () => ({
    type: USER_LOGGED_OUT
});

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case USER_LOGGED:
        return {
            ...state,
            error: null,
            userData: action.userData,
            loginState: UserState.LOGGED
        };
    case USER_LOGGED_OUT:
        localStorage.removeItem('token');
        return {
            ...state,
            error: null,
            userData: null,
            loginState: UserState.NOT_LOGGED
        };
    default:
        return state;
    }
};

export {
    reducer as default,
    initialState as userStateInitialState,
    UserState,
    UserStates,
    getIsLogged,
    setUserLogged,
    setUserLoggedOut
};
