exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #000000 */ /* 菜单栏颜色 按钮颜色 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* 自定义颜色 */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .index_modal-content_25Ihg {\n    width: 70%;\n    height: 70%;\n    padding: 0 30px 0 30px;\n    background-color: white;\n} .index_account-box_2kaAB {\n    margin-right: 30px;\n    margin-left: 30px;\n} input::-webkit-input-placeholder {\n    color: hsla(27, 7%, 51%, 1);\n}\n\n\n", ""]);

// exports
exports.locals = {
	"modal-content": "index_modal-content_25Ihg",
	"modalContent": "index_modal-content_25Ihg",
	"account-box": "index_account-box_2kaAB",
	"accountBox": "index_account-box_2kaAB"
};