import {uploadOss} from './api/oss.js';
import dataURItoBlob from './data-uri-to-blob.js';
import {putWork, uploadWork} from './api/home.js';

export default (filename, blob, id, mainCallBack, errorCallBack) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    // Use special ms version if available to get it working on Edge.
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
        return;
    }
    console.log(id);
    // blob to file
    const file = new File([blob], filename, {type: blob.type, lastModified: Date.now()});
    const formData = new FormData();
    formData.append('sb3', file);

    uploadOss('sb3', file, null, callBack => {
        // console.log(callBack);
        window.scratch.getProjectCover(cover => {
            // console.log(cover);
            const imgBlob = dataURItoBlob(cover);
            const imageFile = new File([imgBlob], `${filename}.png`, {type: imgBlob.type, lastModified: Date.now()});
            uploadOss('img', imageFile, null, callBack2 => {
                // console.log(callBack2);
                new Promise((resolve, reject) => {
                    if (id !== null && id > 0) {
                        putWork(resolve, reject, {
                            title: filename.split('.')[0],
                            cover: callBack2,
                            sourceUrl: callBack,
                            id: id
                        });
                    } else {
                        uploadWork(resolve, reject, {
                            title: filename.split('.')[0],
                            cover: callBack2,
                            sourceUrl: callBack,
                            type: 3,
                            courseType: 3
                        });
                    }
                }).then(response => {
                    console.log(response);
                    mainCallBack(response.data);
                })
                    .catch(error => {
                        console.log(error);
                        errorCallBack(error);
                    });
            });
        });
    });

    // window.scratch.getProjectFile(file => {
    //     console.log(file);
    //
    // });

    // const url = window.URL.createObjectURL(blob);
    // downloadLink.href = url;
    // downloadLink.download = filename;
    // downloadLink.type = blob.type;
    // downloadLink.click();
    // window.URL.revokeObjectURL(url);
    // document.body.removeChild(downloadLink);

    // if ('download' in HTMLAnchorElement.prototype) {
    //     const url = window.URL.createObjectURL(blob);
    //     downloadLink.href = url;
    //     downloadLink.download = filename;
    //     downloadLink.type = blob.type;
    //     downloadLink.click();
    //     // remove the link after a timeout to prevent a crash on iOS 13 Safari
    //     window.setTimeout(() => {
    //         document.body.removeChild(downloadLink);
    //         window.URL.revokeObjectURL(url);
    //     }, 1000);
    // } else {
    //     // iOS 12 Safari, open a new page and set href to data-uri
    //     let popup = window.open('', '_blank');
    //     const reader = new FileReader();
    //     reader.onloadend = function () {
    //         popup.location.href = reader.result;
    //         popup = null;
    //     };
    //     reader.readAsDataURL(blob);
    // }

};
