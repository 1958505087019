import request, {miniFetch} from '../../lib/api/request.js';

// 登录一：首页打开Scratch时，自动获取一次用户登录信息
const requestSession = (resolve, reject) => request(resolve, reject, '/user/getSession');
// 登录二：提交账号、密码进行登录
const requestLogin = function (resolve, reject, data) {
    return miniFetch(resolve, reject, {
        url: '/v1/user/loginByEmail',
        method: 'post',
        mode: 'cors',
        data: data
    });
};
// 获取用户信息
const requestUserInfo = function (resolve, reject) {
    return miniFetch(resolve, reject, {
        url: '/v1/user/getUserInfo',
        method: 'get',
        mode: 'cors'
    });
};
// 获取邮箱验证码
const requestEmailCode = function (resolve, reject, data) {
    return miniFetch(resolve, reject, {
        url: '/v1/user/email/code',
        method: 'post',
        mode: 'cors',
        data: data
    });
};
// 邮箱注册
const requestEmailRegister = function (resolve, reject, data) {
    return miniFetch(resolve, reject, {
        url: '/v1/user/registerByEmail',
        method: 'post',
        mode: 'cors',
        data: data
    });
};
// 获取用户作品
const requestMyProjects = function (resolve, reject) {
    return miniFetch(resolve, reject, {
        url: '/v1/worksLib/scratch',
        method: 'get',
        mode: 'cors'
    });
};
// 退出：提交账号，退出登录状态
const requestLogout = (resolve, reject, data) => request(resolve, reject, '/user/logout', {body: data});
// 获取项目源代码
const requestProject = (resolve, reject, projectId) => request(resolve, reject, `/scratch/project/${projectId}`);
// 保存标题
const requestSaveProjectTitle = (resolve, reject, projectId, projectTitle) => {
    request(resolve, reject, '/scratch/saveProjcetTitle', {body: `id=${projectId}&title=${projectTitle}`});
};
// 保存缩略图
const requestSaveProjectThumbnail = (resolve, reject, projectId, thumbnailBlob) => {
    request(resolve, reject, `/scratch/thumbnail/${projectId}`, {
        body: thumbnailBlob,
        headers: {'Content-Type': 'image/png'}
    });
};
// 保存分享
const requestShareProject = (resolve, reject, projectId) => {
    request(resolve, reject, `/scratch/shareProject/${projectId}`);
};

export {
    requestSession,
    requestLogin,
    requestUserInfo,
    requestEmailCode,
    requestEmailRegister,
    requestLogout,
    requestProject,
    requestSaveProjectTitle,
    requestSaveProjectThumbnail,
    requestShareProject,
    requestMyProjects
};
