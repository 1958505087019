import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import {injectIntl, intlShape} from 'react-intl';

import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {getIsError, getIsShowingProject} from '../reducers/project-state';
import {activateTab, BLOCKS_TAB_INDEX, COSTUMES_TAB_INDEX, SOUNDS_TAB_INDEX} from '../reducers/editor-tab';

import {
    closeBackdropLibrary,
    closeCostumeLibrary,
    closeLoginModal,
    closeMyProjectsModal,
    closeTelemetryModal,
    openExtensionLibrary
} from '../reducers/modals';

import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import SBFileUploaderHOC from '../lib/sb-file-uploader-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import TitledHOC from '../lib/titled-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import {setIsScratchDesktop} from '../lib/isScratchDesktop.js';

import {getIsLogged, setUserLogged, setUserLoggedOut} from '../reducers/user-state.js';
import '../sdk/index';
import {ToastProvider} from 'react-toast-notifications';

class GUI extends React.Component {
    componentDidMount () {
        setIsScratchDesktop(this.props.isScratchDesktop);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);
        this.isDefaultProjectLoaded = false;

        window.scratch = window.scratch || {};
        const that = this;
        document.addEventListener('loadProject', e => {
            that.loadProjectByURL(e.detail.url, e.detail.callback);
        });
        document.addEventListener('getProjectFile', e => {
            that.getProjectFile(e.detail.callback);
        });
        document.addEventListener('getProjectCover', e => {
            that.getProjectCover(e.detail.callback);
        });
        document.addEventListener('getProjectCoverBlob', e => {
            that.getProjectCoverBlob(e.detail.callback);
        });

        window.scratch.getProjectCover = callback => {
            const event = new CustomEvent('getProjectCover', {detail: {callback: callback}});
            document.dispatchEvent(event);
        };

        window.scratch.getProjectCoverBlob = callback => {
            const event = new CustomEvent('getProjectCoverBlob', {detail: {callback: callback}});
            document.dispatchEvent(event);
        };

        window.scratch.getProjectFile = callback => {
            const event = new CustomEvent('getProjectFile', {detail: {callback: callback}});
            document.dispatchEvent(event);
        };

        window.scratch.loadProject = (url, callback) => {
            const event = new CustomEvent('loadProject', {detail: {url: url, callback: callback}});
            document.dispatchEvent(event);
        };

        // if (window.scratchConfig && 'handleVmInitialized' in window.scratchConfig) {
        //     window.scratchConfig.handleVmInitialized(this.props.vm);
        // }
    }

    componentDidUpdate (prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();

            // 加载项目回调
            // if (window.scratchConfig && 'handleProjectLoaded' in window.scratchConfig) {
            //     window.scratchConfig.handleProjectLoaded();
            // }
            //
            // // 加载默认项目回调
            // if (!this.isDefaultProjectLoaded) {
            //     this.isDefaultProjectLoaded = true;
            //     if (window.scratchConfig && 'handleDefaultProjectLoaded' in window.scratchConfig) {
            //         window.scratchConfig.handleDefaultProjectLoaded();
            //     }
            // }
        }

        if (this.props.isRealtimeMode !== true) {
            this.props.onActivateBlocksTab();
        }
    }

    getProjectFile (callback) {
        this.props.vm.saveProjectSb3()
            .then(res => {
                callback(res);
            });
    }

    getProjectCover (callback) {
        this.props.vm.postIOData('video', {forceTransparentPreview: true});
        this.props.vm.renderer.requestSnapshot(dataURI => {
            this.props.vm.postIOData('video', {forceTransparentPreview: false});
            callback(dataURI);
        });
        this.props.vm.renderer.draw();
    }

    getProjectCoverBlob (callback) {
        this.props.vm.renderer.draw();
        const canvas = this.props.vm.renderer.canvas;
        canvas.toBlob(blob => {
            callback(blob);
        });
    }

    loadProjectByURL (url, callback) {
        console.log(`从URL加载项目${url}`);
        // this.props.onLoadingStarted()
        // this.props.vm.clear()
        return fetch(url)
            .then(r => r.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
                    this.props.vm.loadProject(reader.result)
                        .then(() => {
                            // this.props.onUpdateProjectTitle(projectName)
                            //   this.props.onLoadedProject(this.props.loadingState, this.props.canSave);
                            //   setTimeout(() => this.props.onSetProjectUnchanged());
                            //   if (!this.props.isStarted) {
                            //     setTimeout(() => this.props.vm.renderer.draw());
                            //   }
                            callback();
                        })
                        .catch(e => {
                            callback(e);
                        });
                };
                reader.readAsArrayBuffer(blob);
            })
            .catch(e => {
                callback(e);
            });
    }

    render () {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onActivateBlocksTab,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onUpdateProjectTitle,
            onVmInit,
            projectHost,
            projectId,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            ...componentProps
        } = this.props;
        return (
            <ToastProvider
                autoDismiss
                autoDismissTimeout={3000}
                newestOnTop
            >
                <GUIComponent
                    loading={fetchingProject || isLoading || loadingStateVisible}
                    {...componentProps}
                >
                    {children}
                </GUIComponent>
            </ToastProvider>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    onActivateBlocksTab: PropTypes.func,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    onVmInit: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    telemetryModalVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    loginState: PropTypes.bool,
    isRealtimeMode: PropTypes.bool
};

GUI.defaultProps = {
    isScratchDesktop: false,
    isShowingProject: false,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {
    },
    onUpdateProjectId: () => {

    },
    onUpdateProjectTitle: () => {
    },
    onVmInit: vm => {
        // vm.extensionManager.loadExtensionURL('matatabot');
        // vm.extensionManager.loadExtensionURL('matatacon');
        // vm.extensionManager.loadExtensionURL('matatacar');
    }
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const loginState = state.scratchGui.userState.loginState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        firmwareUpdateModalVisible: state.scratchGui.modals.firmwareUpdateModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm,
        showLoginVisible: state.scratchGui.modals.loginModal,
        loginState: getIsLogged(loginState),
        userData: state.scratchGui.userState.userData,
        showLoadingVisible: state.scratchGui.modals.loadingModal,
        myProjectsVisible: state.scratchGui.modals.myProjectsModal,
        isRealtimeMode: state.scratchGui.programMode.isRealtimeMode
    };
};

const mapDispatchToProps = dispatch => ({
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateBlocksTab: () => dispatch(activateTab(BLOCKS_TAB_INDEX)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal()),
    onCancelLogin: () => dispatch(closeLoginModal()),
    onUserLogged: data => dispatch(setUserLogged(data)),
    onUserLoggedOut: () => dispatch(setUserLoggedOut()),
    onCancelProjects: () => dispatch(closeMyProjectsModal())
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    TitledHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    SBFileUploaderHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
