import React from 'react';
import Button from '../button/button.jsx';
import styles from './login.css';
import PropTypes from 'prop-types';


/** Component: countdown click button class */
class VerifyCode extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            time: 60,
            enable: false,
            text: '获取验证码'
        };
        this.timer = null;
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillUnmount () {
        clearInterval(this.timer);
    }

    handleClick () {
        const {time, enable} = this.state;
        const {onClick, disabled} = this.props;
        onClick();
        // if (!disabled) {
        //     return;
        // }
        this.timer = setInterval(() => {
            if (this.state.time > 0) {
                this.setState({
                    time: this.state.time - 1,
                    enable: true,
                    text: `${this.state.time}s后重试`
                });
            } else {
                this.setState({
                    time: 60,
                    enable: false,
                    text: '获取验证码'
                });
                clearInterval(this.timer);
            }
        }, 1000);
        // }
    }

    render () {
        const {time, enable} = this.state;
        const {disabled} = this.props;
        return (
            <Button
                className={(enable || disabled) ? styles.codeButtonDisabled : styles.codeButton}
                disabled={disabled}
                onClick={this.handleClick}
            >
                {this.state.text}
            </Button>
        );
    }
}

// class VerifyCode extends React.Component {
//     constructor (props) {
//         super(props);
//         this.state = {
//             time: 60,
//             disabled: false,
//             text: '获取验证码'
//         };
//         this.timer = null;
//         // bind onclick event
//         this.onClick = this.handleClick().bind(this);
//     }
//
//     componentDidMount () {
//         this.timer = setInterval(() => {
//             if (this.state.time > 0) {
//                 this.setState({
//                     time: this.state.time - 1,
//                     disabled: true,
//                     text: `${this.state.time}s后重试`
//                 });
//             } else {
//                 this.setState({
//                     time: 60,
//                     disabled: false,
//                     text: '获取验证码'
//                 });
//                 clearInterval(this.timer);
//             }
//         }, 1000);
//     }
//
//     componentWillUnmount () {
//         clearInterval(this.timer);
//     }
//
//     handleClick = () => {
//
//     };
//
//     render () {
//         return (
//             <Button
//                 className={styles.codeButton}
//                 disabled={this.state.disabled}
//                 onClick={this.handleClick}
//             >
//                 {this.state.text}
//             </Button>
//         );
//     }
// }


// let timer = null;
//
// class VerifyCode extends React.Component {
//     constructor (props) {
//         super(props);
//         this.state = {
//             time: 60,
//             disabled: false,
//             onClick: this.onClick.bind(this)
//         };
//     }
//
//     handleCode = () => {
//         // eslint-disable-next-line no-unused-expressions
//         onClick && onClick(() => {
//             this.setState({timer: 60, disabled: true});
//         });
//         // 直接使用
//         this.setState({timer: 60, disabled: true});
//     };
//
//     render () {
//         const {onClick} = this.props;
//         // const [time, setTime] = useState(0);
//
//         useEffect(() => {
//             // eslint-disable-next-line no-unused-expressions
//             timer && clearInterval(timer);
//             return () => timer && clearInterval(timer);
//         }, []);
//
//         useEffect(() => {
//             if (this.state.time === 60) {
//                 timer = setInterval(() => this.setState(time => --time), 1000);
//             } else if (this.state.time === 0) clearInterval(timer);
//         }, [this.state.time]);
//
//         return (
//             <Button
//                 onClick={this.handleCode}
//                 className={styles.codeButton}
//             >
//                 {this.state.time ? `${this.state.time}秒后获取` : '获取验证码'}
//             </Button>
//         );
//     }
// }
//
VerifyCode.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.string
};
//
// VerifyCode.defaultProps = {
//     onClick: null
// };

export default VerifyCode;
