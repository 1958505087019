exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #000000 */ /* 菜单栏颜色 按钮颜色 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* 自定义颜色 */ .save-button_save-button_3WfD8 {\n    background: hsla(29, 85%, 67%, 1);\n    border: 1px solid #F2A863;\n    border-radius: 30px;\n} .save-button_save-button-is-shared_2h3K4 {\n    background: hsla(30, 81%, 94%, 1);\n    cursor: default;\n}\n", ""]);

// exports
exports.locals = {
	"save-button": "save-button_save-button_3WfD8",
	"saveButton": "save-button_save-button_3WfD8",
	"save-button-is-shared": "save-button_save-button-is-shared_2h3K4",
	"saveButtonIsShared": "save-button_save-button-is-shared_2h3K4"
};