import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {setDeviceId, setDeviceName, setDeviceType} from '../reducers/device';

/**
 * Editor Mode component passes toggleEditorMode function to its child.
 * It also includes `editorMode` in the props passed to the children.
 * It expects this child to be a function with the signature
 *     function (toggleEditorMode, {editorMode, ...props}) {}
 * The component can then be used to attach editor mode setting functionality
 * to any other component:
 *
 * <EditorMode>{(toggleEditorMode, props) => (
 *     <MyCoolComponent
 *         turboEnabled={props.editorMode}
 *         onClick={toggleEditorMode}
 *         {...props}
 *     />
 * )}</EditorMode>
 */
class EditorMode extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'toggleEditorMode'
        ]);
    }

    toggleEditorMode () {
        if (this.props.editorMode) {
            this.props.onDeviceSelected('matataCar', 'matataCar', 'matataCar');
        } else {
            this.props.onDeviceSelected(null, null, 'matatabot');
        }
        this.props.vm.setRealtimeMode(!this.props.editorMode);
    }

    render () {
        const {
            /* eslint-disable no-unused-vars */
            children,
            vm,
            /* eslint-enable no-unused-vars */
            ...props
        } = this.props;
        return this.props.children(this.toggleEditorMode, props);
    }
}

EditorMode.propTypes = {
    children: PropTypes.func,
    editorMode: PropTypes.bool,
    vm: PropTypes.shape({
        setRealtimeMode: PropTypes.func
    }),
    onDeviceSelected: PropTypes.func
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    editorMode: state.scratchGui.programMode.isRealtimeMode
    // editorMode: state.scratchGui.vm.runtime.getCurrentIsRealtimeMode()
});

const mapDispatchToProps = dispatch => ({
    onDeviceSelected: (id, name, type) => {
        dispatch(setDeviceId(id));
        dispatch(setDeviceName(name));
        dispatch(setDeviceType(type));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditorMode);
