import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';

import Modal from './Modal.js';
import LoadingBubbles from './LoadingBubbles.js';

const ModalWrapper = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalCard = styled.div`
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: transparent;
    //border-radius: 6px;
    color: #333;
    text-align: left;
    padding: 15px;
    flex-direction: column;
    //box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
`;

class LoadingOverlayModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: ''
        };
    }

    getMessage () {
        // login success
        return (
            <div className={styles.errorMessage}>
                <FormattedMessage
                    defaultMessage="加载中"
                    description="Label for login success"
                    id="gui.account.loginSuccess"
                />
            </div>
        );
    }

    render () {
        // const {intl, onClose, isOpen} = this.props;
        const {error, errorMessage} = this.state;
        return (<Modal>
            <ModalWrapper>
                <ModalCard>
                    <LoadingBubbles
                        size={128}
                        color="#4C97FF"
                    />
                </ModalCard>
            </ModalWrapper>
        </Modal>);
    }
}

LoadingOverlayModal.propTypes = {
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    loginError: PropTypes.string
};

LoadingOverlayModal.defaultProps = {
    title: '欢迎来到Matatalab',
    loginError: ''
};

export default injectIntl(LoadingOverlayModal);
