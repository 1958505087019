import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import uploadBlob from '../lib/upload-blob';

/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Uploader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Uploader>
 */
class SB3Uploader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }

    downloadProject (resolve, reject) {
        this.props.saveProjectSb3()
            .then(content => {
                if (this.props.onSaveFinished) {
                    this.props.onSaveFinished();
                }
                uploadBlob(this.props.projectFilename, content, this.props.mataProjectId, resolve, reject);
            });
        // window.scratch.getProjectFile(file => {
        //     console.log(file);
        //     window.scratch.getProjectCover(cover => {
        //         console.log(cover);
        //         // 获取到项目名
        //         const projectName = window.scratch.getProjectName();
        //         console.log(projectName);
        //     });
        // });
    }

    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Uploader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func,
    mataProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
SB3Uploader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState),
    mataProjectId: state.scratchGui.projectState.mataProjectId
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Uploader);
