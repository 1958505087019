/**
 * 封装fetch请求
 */
export const miniFetch = (resolve, reject, options = {}) => {
    const {data, url} = options;
    options = {...options};
    // 跨域
    options.mode = 'cors';
    options.host = process.env.API_HOST;
    delete options.url;
    if (data) {
        delete options.data;
        options.body = JSON.stringify(data);
    }
    options.headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json,text/plain,*/*',
        ...options.headers
    };
    if (localStorage.getItem('token') !== null) {
        options.headers.Authorization = localStorage.getItem('token');
    }
    // const opts = {
    //     host: process.env.API_HOST,
    //     headers: {
    //         'Accept': 'application/json,text/plain,*/*', /* 格式限制：json、文本、其他格式 */
    //         'Content-Type': 'application/x-www-form-urlencoded'/* 请求内容类型 */
    //     },
    //     method: 'post',
    //     mode: 'cors'
    // };
    // if (params) {
    //     if (params.headers) {
    //         opts.headers = Object.assign(opts.headers, params.headers);
    //     }
    //     if (params.method) {
    //         opts.method = params.method;
    //     }
    //     if (params.body) {
    //         opts.body = params.body;
    //     }
    // }

    // opts.uri = opts.host + opts.url;
    fetch(options.host + url, options, {credentials: 'include'})
        .then(response => {
            if (response.status === 401) {
                localStorage.clear();
            }
            const body = response.json();
            if (response.status >= 200 && response.status < 300) {
                return resolve(body);
            }
            return reject(body);
        })
        .catch(err => reject(err));
};

export const asyncFetch = (options = {}) => {
    const {data} = options;
    options = {...options};
    // 跨域
    options.mode = 'cors';
    options.host = process.env.API_HOST;

    const request = new XMLHttpRequest();
    request.open(options.method, options.host + options.url, false);
    request.setRequestHeader('Content-Type', 'application/json');
    request.setRequestHeader('Accept', 'application/json');
    if (localStorage.getItem('token') !== null) {
        request.setRequestHeader('Authorization', localStorage.getItem('token'));
    }
    if (options.headers) {
        for (const key in options.headers) {
            request.setRequestHeader(key, options.headers[key]);
        }
    }
    request.setRequestHeader('mode', 'cors');
    request.send(JSON.stringify(data));
    if (request.status === 401) {
        localStorage.clear();
    }
    if (request.status >= 200 && request.status < 300) {
        return JSON.parse(request.responseText);
    }
    return request.response;
};

const parseJSON = function (response) {
    return response.json();
};
const checkStatus = function (response) {
    if (response.status === 401) {
        localStorage.removeItem('token');
    }
    if (response.status >= 200 && response.status < 500) {
        return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};

export default (options = {}) => {
    const {data, url} = options;
    options = {...options};
    // 跨域
    options.mode = 'cors';
    options.host = process.env.API_HOST;
    delete options.url;
    if (data) {
        delete options.data;
        options.body = JSON.stringify(data);
    }
    options.headers = {
        'Content-Type': 'application/json'
    };
    return fetch(options.host + url, options, {credentials: 'include'})
        .then(checkStatus)
        .then(parseJSON)
        .catch(err => ({err}));
};
