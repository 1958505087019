import CustomModal from '../../containers/custom-modal.jsx';
import styles from './index.css';
import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';
import closeMyProjectsModal, {closeLoadingModal, openLoadingModal} from '../../reducers/modals.js';
import {requestMyProjects} from '../../lib/api/login.js';
import {notScratchDesktop} from '../../lib/isScratchDesktop.js';
import tutorialTags from '../../lib/libraries/tutorial-tags.js';
import bindAll from 'lodash.bindall';
import GridComponent from './grid.jsx';
import {useToasts} from 'react-toast-notifications';
import {connect} from 'react-redux';
import {setProjectUnchanged} from '../../reducers/project-changed';
import {setMataProjectId} from '../../reducers/project-state';

class MyProjectModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isAgree: false,
            isClosed: false,
            myProjectsData: []
        };
        bindAll(this, [
            'handleSubmit',
            'handleItemSelect'
        ]);

        this.handleRegister = () => {
            this.setState({
                isRegister: !this.state.isRegister
            });
        };
    }

    componentDidMount () {
        this.requestMyProjects();
    }

    handleItemSelect (item) {
        // analytics.event({
        //     category: 'library',
        //     action: 'Select How-to',
        //     label: item.id
        // });
        this.props.onLoadingStarted();
        if (item.requiredProjectId && (item.requiredProjectId !== this.props.projectId)) {
            const urlParams = `/projects/${item.requiredProjectId}/editor?tutorial=${item.urlId}`;
            return window.open(window.location.origin + urlParams, '_blank');
        }

        // this.props.onActivateDeck(item.id);
        // activateDeck(item.id);
        this.loadProjectByURL(item);
    }

    loadProjectByURL (item) {
        console.log(`从URL加载项目${item.id}`);
        // this.props.onLoadingStarted()
        // this.props.vm.clear()
        const that = this;
        this.props.setMataProjectId(item.id);
        return fetch(item.projectUrl)
            .then(r => r.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
                    that.props.vm.loadProject(reader.result)
                        .then(() => {
                            window.scratch.setProjectName(item.name);
                            //   this.props.onLoadedProject(this.props.loadingState, this.props.canSave);
                            setTimeout(() => this.props.onSetProjectUnchanged());
                            if (!this.props.isStarted) {
                                setTimeout(() => that.props.vm.renderer.draw());
                            }
                            this.props.onLoadingFinished();
                            console.log('加载完成', this.props.mataProjectId);
                        })
                        .catch(e => {
                            console.error(e);
                            // callback(e);
                            this.props.callBack(e);
                            this.props.onLoadingFinished();
                        });
                };
                reader.readAsArrayBuffer(blob);
            })
            .catch(e => {
                this.props.onLoadingFinished();
                this.props.callBack(e);
            });
    }

    handleSubmit (event) {
        event.preventDefault();
    }

    requestMyProjects () {
        new Promise((resolve, reject) => {
            requestMyProjects(resolve, reject);
        })
            .then(response => {
                const myData = response.data;
                console.log('parsed json', myData);
                this.setState({
                    myProjectsData: myData.filter(id => {
                        if (notScratchDesktop()) return true; // Do not filter anything in online editor
                        const deck = myData[id];
                        // Scratch Desktop doesn't want tutorials with `requiredProjectId`
                        if (deck.hasOwnProperty('requiredProjectId')) return false;
                        // Scratch Desktop should not load tutorials that are _only_ videos
                        if (deck.steps.filter(s => s.title).length === 0) return false;
                        // Allow any other tutorials
                        return true;
                    })
                        .map(data => ({
                            rawURL: data.cover,
                            id: data.id,
                            name: data.title,
                            featured: true,
                            projectUrl: data.sourceUrl,
                            urlId: data.urlId,
                            requiredProjectId: data.requiredProjectId,
                            hidden: data.hidden || false
                        }))
                });
            })
            .catch(ex => {
                console.log('parsing catch', ex);
                ex.then(response => {
                    const {addToast} = useToasts();
                    addToast(response, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
            });
    }

    render () {
        // const {intl, onClose, isOpen} = this.props;
        return (<CustomModal
            className={styles.modalContent}
            contentLabel={this.props.title}
            id="projectModal"
            onRequestClose={this.props.onCancel}
        >
            <GridComponent
                filterable
                data={this.state.myProjectsData}
                id="myProjects"
                tags={tutorialTags}
                visible
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onCancel}
            />
        </CustomModal>);
    }
}

MyProjectModal.propTypes = {
    callBack: PropTypes.func,
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    intl: intlShape.isRequired,
    // onActivateDeck: PropTypes.func.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onLoadingStarted: PropTypes.func,
    onLoadingFinished: PropTypes.func,
    onSetProjectUnchanged: PropTypes.func,
    isStarted: PropTypes.bool,
    setMataProjectId: PropTypes.func,
    mataProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MyProjectModal.defaultProps = {
    title: '打开作品',
    onCancel: () => {
        closeMyProjectsModal();
    },
    loginError: ''
};

const mapStateToProps = state => ({
    mataProjectId: state.scratchGui.projectState.mataProjectId,
    isStarted: state.scratchGui.vmStatus.started
});

const mapDispatchToProps = dispatch => ({
    onLoadingStarted: () => dispatch(openLoadingModal()),
    onLoadingFinished: () => dispatch(closeLoadingModal()),
    setMataProjectId: projectId => dispatch(setMataProjectId(projectId)),
    onSetProjectUnchanged: () => dispatch(setProjectUnchanged())
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProjectModal));
