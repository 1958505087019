import {asyncFetch} from './request.js';
import OSS from 'ali-oss';

// 获取临时授权
const getCredit = function (data) {
    return asyncFetch({
        url: `/v1/oss/getCredit/${data}`,
        method: 'get',
        mode: 'cors',
        headers: {
            'x-oss-forbid-overwrite': true
        }
    });
};

const dataURLtoBlob = function (dataUrl) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bStr = atob(arr[1]);
    let n = bStr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bStr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
};

const OssClient = function (res, fileType) {
    return new OSS({
        endpoint: 'https://static.matatalab.com',
        region: 'oss-cn-shenzhen',
        accessKeyId: res.accessKeyId,
        accessKeySecret: res.accessKeySecret,
        bucket: res.bucket,
        expiration: res.expiration,
        stsToken: res.securityToken,
        secure: true,
        cname: true,
        refreshSTSToken: () => {
            const refreshToken = getCredit({
                headers: {
                    'x-oss-forbid-overwrite': true
                },
                fileType: fileType || 'img'
            });
            return {
                accessKeyId: refreshToken.AccessKeyId,
                accessKeySecret: refreshToken.AccessKeySecret,
                stsToken: refreshToken.SecurityToken,
                expiration: refreshToken.expiration
            };
        }
    });
};

const uploadOss = function (fileType, file, progressHandle, callBack) {
    console.log(file);
    const res = getCredit(fileType);
    if (res.code === 200) {
        console.log(res);
        const basePath = res.data.basePath;
        const ossOptions = OssClient(res.data, fileType);
        let headers = {};

        if (file.name.includes('.mp4')) {
            headers = {
                'Content-Type': 'video/mp4'
            };
        }
        if (file.size > 1024 * 1024 * 100) {
            ossOptions.multipartUpload(`${basePath}/${file.name}`, file, {
                progress: function (p) {
                    progressHandle && progressHandle(p);
                    console.log(p);
                },
                headers: headers
            })
                .then(resOss => {
                    console.log(resOss);
                    resOss.completeMultipartUpload(`${basePath}/${file.name}`, res.headers['x-oss-request-id']);
                    // console.log(resOss.res.requestUrls[0]);
                    console.log(resOss);
                    callBack(resOss.res.requestUrls[0]);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            ossOptions.put(`${basePath}/${file.name}`, file, {
                progress: progressHandle,
                headers: headers
            })
                .then(resOss => {
                    console.log(resOss);
                    // console.log(resOss.res.requestUrls[0]);
                    callBack(resOss.res.requestUrls[0]);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        // return resOss.res.requestUrls[0];
    }
};

export {
    getCredit,
    uploadOss
};
